import { useState, useEffect } from "react";
import FrontQt from "./assets/qt_front_outline.gif";
import SwimQt from "./assets/qt_swim_outline.gif";
import CalmQt from "./assets/qt_calm_underwater.gif";

const angle = (cx, cy, ex, ey) => {
  const dy = ey - cy;
  const dx = ex - cx;
  const rad = Math.atan2(dy, dx);
  const deg = (rad * 180) / Math.PI;

  return deg;
};

const STATES = {
  FRONT: "FRONT",
  SWIM: "SWIM",
  CALM: "CALM",
};

const returnImg = (state) => {
  switch (state) {
    case STATES.FRONT:
      return FrontQt;
    case STATES.SWIM:
      return SwimQt;
    case STATES.CALM:
      return CalmQt;

    default:
      return FrontQt;
  }
};

export default function App() {
  const [qtState, setQtState] = useState(STATES.FRONT);
  /*  const [haveMouseMoved, setHaveMouseMoved] = useState(false);

  useEffect(() => {
    document.addEventListener("mousemove", (e) => {
      const mouseX = e.clientX;
      const mouseY = e.clientY;
      const wrapper = document.getElementById("QtWrapper");
      const rekt = wrapper.getBoundingClientRect();
      const wrapperX = rekt.left + rekt.width / 2;
      const wrapperY = rekt.top + rekt.height / 2;
      const angleDeg = angle(mouseX, mouseY, wrapperX, wrapperY);

      setQtState(STATES.SWIM);
      setHaveMouseMoved(true);

      const Qt = document.getElementById("Qt");

      Qt.style.transform = `rotate(${-90 + angleDeg}deg)`;

      Qt.style.top = `${mouseY / 5}px`;
      Qt.style.left = `${mouseX / 5}px`;
    });

    return () => {
      document.removeEventListener("mousemove", () => {});
    };
  }, []); */

  return (
    <div className="QtContainer">
      <div id="QtWrapper">
        <img id="Qt" src={returnImg(qtState)} />
      </div>
    </div>
  );
}
